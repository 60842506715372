import { Container, H1, H2, H3, Line, Cabecalho, Row, Corpo, Button } from './styles.js'
import { useState } from 'react'
import Modal from '../modal'
const MainContainer = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  return (
    <Container>
      <Modal isOpen={isModalOpen} onClose={closeModal} />
      <Cabecalho>
        <Row>
          <svg viewBox="0 0 100 100" width="500" height="500">
            <g fill="#49beb7">
              <path d="M8.922,38.658V56.34c0,4.787,3.893,8.68,8.68,8.68h12.377v12.379c0,4.785,3.895,8.68,8.68,8.68H56.34c4.787,0,8.68-3.895,8.68-8.68V65.02h12.379c4.785,0,8.68-3.893,8.68-8.68V38.658c0-4.787-3.895-8.68-8.68-8.68H65.02V17.602c0-4.787-3.893-8.68-8.68-8.68H38.658c-4.785,0-8.68,3.893-8.68,8.68v12.377H17.602C12.814,29.979,8.922,33.871,8.922,38.658z M13.422,38.658c0-2.305,1.875-4.18,4.18-4.18h12.377h4.5H56.02v-4.5H34.479V17.602c0-2.305,1.875-4.18,4.18-4.18H56.34c2.305,0,4.18,1.875,4.18,4.18v12.377v4.5V56.02h4.5V34.479h12.379c2.303,0,4.178,1.875,4.178,4.18V56.34c0,2.305-1.875,4.18-4.178,4.18H65.02h-4.5H38.98v4.5H60.52v12.379c0,2.305-1.875,4.178-4.18,4.178H38.658c-2.305,0-4.18-1.873-4.18-4.178V65.02v-4.5V38.98h-4.5V60.52H17.602c-2.305,0-4.18-1.875-4.18-4.18V38.658z"></path>
            </g>
          </svg>
          <H1>MouthCheck</H1>
        </Row>
        <Line />
      </Cabecalho>
      <Corpo>
        <H2>Faça o upload de uma imagem para detectar câncer de boca</H2>
        <H3>Por favor, faça o upload de uma imagem nítida e bem iluminada do interior de sua boca para que possamos realizar uma análise detalhada e precisa. Quanto mais nítida e clara a imagem estiver, melhor será a qualidade da análise e mais acurados serão os resultados.</H3>
        <Button onClick={openModal}>Enviar Imagem</Button>
      </Corpo>

    </Container>
  )
}

export default MainContainer
