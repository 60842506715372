import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-size: 1rem;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }

  body {
    -webkit-font-smoothing: antialiased;
    color: black;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    flex-direction: column;
    display: flex;
    flex: 1;
    background-color: #c8d6e5;
  }
   svg {
    height: 24px;
    width: 24px;
  }



  body, input, button, span, textarea {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 300;
}

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

    @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }
`

