import styled from 'styled-components'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 80vh;
  background-color: #f0f0f0;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  `

export const H1 = styled.h1`
  font-size: 1.4rem;
  font-weight: 700;
  color: #333;
  padding-bottom: 10px;
  `

export const Line = styled.div`
  width: calc(100% + 40px);
  height: 1px;
  background-color: #ccc;
  margin-left: -20px;
  `

export const Cabecalho = styled.div`
  display: flex;
  flex-direction: column;
  `

export const Row = styled.div`
display: flex;
flex-direction: row;
gap: 5px;
`
export const Corpo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
  height: 80%;
  justify-content: center;
  gap: 20px;
  `

export const H2 = styled.h2`
  font-size: 1.8rem;
  font-weight: 700;
  color: black;
  padding-bottom: 10px;
  text-align: center;
  `

export const H3 = styled.h3`
  font-size: 0.9rem;
  font-weight: 400;
  color: #666;
  padding-bottom: 10px;
  width: 70%;
  text-align: center;
  `

export const Button = styled.button`
  background-color: #019863;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #017c52;
  }

  &:focus {
    outline: none;
  }
`;